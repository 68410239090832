<template>
    <modal :show="show"
           @close="close"
           body-classes="p-0">
        <card type="secondary" shadow class="border-0 mb-0">
            <template>
              <h5>Sub Programs</h5>
              <div v-for="subProgram in subPrograms" :key="subProgram.count" class="row">
                <div class="col-sm-5 col-5 p-0 pl-3">
                    <base-input placeholder="Sub Program name" v-model="subProgram.subProgramTitle"></base-input>
                </div>
                <div class="col-sm-5 col-4 px-2">
                  <base-input placeholder="Tweets number" v-model="subProgram.numberOfTweets" type="number" min="0"></base-input>
                </div>
                <div class="col-sm-2 col-2 p-0">
                  <base-button v-if="isFirstSubProgramBox(subProgram)" type="primary" @click="addSubProgramBox">
                    <i class="fas fa-plus"></i>
                  </base-button>
                  <base-button v-else type="primary" @click="removeSubProgramBox(subProgram)">
                    <i class="fas fa-trash"></i>
                  </base-button>
                </div>
              </div>

              <base-button type="primary" class="w-100" :disabled="!canFormBeSubmitted()" @click="saveGrowthProgram">
                Create Growth Program
              </base-button>
            </template>
        </card>
    </modal>
</template>

<script>
  import moment from 'moment';
  import dao from '@/dao';
  import { GrowthProgram } from '@/models/GrowthProgram';

  export default {
    name: 'new-growth-program-modal',
    props: {
      show: Boolean,
      latestGrowthProgram: Object,
    },
    data () {
      return {
        subPrograms: null,
      };
    },
    methods: {
      addSubProgramBox() {
        const lastSubProgramBox = this.subPrograms[this.subPrograms.length - 1];
        this.subPrograms.push({
          count: lastSubProgramBox.count + 1,
        });
      },
      canFormBeSubmitted() {
        if (!this.subPrograms) return;
        return this.subPrograms.every((subProgram) => (!!subProgram.numberOfTweets &&
          Number(subProgram.numberOfTweets) >= 0 && !!subProgram.subProgramTitle)
        );
      },
      close() {
        this.$emit('close');
      },
      isFirstSubProgramBox(subProgram) {
        return subProgram.count === 0;
      },
      removeSubProgramBox(subProgram) {
        this.subPrograms = this.subPrograms.filter(p => !(p.count === subProgram.count));
      },
      saveGrowthProgram() {
        const nextGrowthProgramDate = moment().add(1, 'month').startOf('month');
        const month = nextGrowthProgramDate.month() + 1;
        const year = nextGrowthProgramDate.year();
        const numberOfTweets = {};
        this.subPrograms.forEach((subProgram) =>
          (numberOfTweets[subProgram.subProgramTitle] = Number(subProgram.numberOfTweets))
        );

        const newGrowthProgram = GrowthProgram.newGrowthProgram(nextGrowthProgramDate.toDate(), month,
          numberOfTweets, year);

        return dao.growthProgram.saveGrowthProgram({...newGrowthProgram})
          .then(() => {
            this.close();
          })
          .catch(error => {
            console.error(error);
            alert('An error has occurred while saving the growth program');
          });
      },
      updateInputsWithLatestGrowthProgramData() {
        const listOfSubPrograms = Object.entries(
          this.latestGrowthProgram.numberOfTweets
        );
        this.subPrograms = listOfSubPrograms.map((subProgram, i) => ({
          count: i,
          subProgramTitle: subProgram[0],
          numberOfTweets: subProgram[1],
        }));
      },
    },
    watch: {
      show(val) {
        if (!val) return;
        this.updateInputsWithLatestGrowthProgramData();
      }
    }
  }
</script>

<style type="scss">
</style>
