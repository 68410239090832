<template>
    <div class="growth-program-old-style">
        <back-to-top-custom />

        <div class="row m-3">
          <div class="col-3">
            <base-button type="primary" class="d-flex" @click="showNewGrowthProgramModal">
              <i class="fas fa-plus mr-2"></i>
              New Program
            </base-button>

            <div class="list-group list-group-flush program-group overflow-auto mt-3">
              <a v-for="growthProgram in growthPrograms"
                 :key="growthProgram.date.seconds"
                 class="list-group-item program"
                 :class="{selected: isSelected(growthProgram)}"
                 @click="switchToGrowthProgram(growthProgram)"
                 href="javascript:;">
                 <div class="d-flex justify-content-between">
                  {{displayGrowthProgramDate(growthProgram.date)}}
                  <i class="fas fa-trash text-danger delete-icon" @click="deleteGrowthProgram(growthProgram)"></i>
                 </div>
              </a>
            </div>
          </div>

          <div class="col-6" v-if="selectedGrowthProgram">
            <base-button type="primary"
                         class="d-flex text-capitalize"
                         @click="showGrowthProgramUsersModal">
              <i class="fas fa-plus"></i>
              Add {{ selectedRole }}
            </base-button>

            <ul class="nav nav-pills nav-fill my-3">
              <li class="nav-item">
                <a class="nav-link"
                   :class="{active: selectedRole === 'customers'}"
                   href="javascript:;"
                   @click="setSelectedRole('customers')">
                  Customers
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link"
                   :class="{active: selectedRole === 'influencers'}"
                   href="javascript:;"
                   @click="setSelectedRole('influencers')">
                  Influencers
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link"
                   :class="{active: selectedRole === 'writers'}"
                   href="javascript:;"
                   @click="setSelectedRole('writers')">
                  Writers
                </a>
              </li>
            </ul>

            <ul class="nav nav-pills nav-fill my-3" v-if="selectedGrowthProgram && selectedRole === 'customers'">
              <li class="nav-item" v-for="subProgram in selectedGrowthProgramSubPrograms" :key="subProgram">
                <a class="nav-link"
                   :class="{active: selectedSubProgram === subProgram}"
                   href="javascript:;"
                   @click="selectedSubProgram = subProgram">
                  {{subProgram}}
                </a>
              </li>
            </ul>

            <div class="list-group growth-program-users" v-if="selectedGrowthProgramUsers && displayUsers.length">
              <a v-for="user in displayUsers"
                 :key="user.uid"
                 href="javascript:;"
                 class="list-group-item list-group-item-action user">
                <div class="d-flex align-items-center justify-content-between">
                  <div>
                    <img :src="user.photoURL" class="avatar mr-3" alt="user.username" />
                    <span>{{ user.username }}</span>
                  </div>
                  <div class="d-flex align-items-center">
                    <badge v-if="selectedRole === 'customers'">{{user.growthProgramName}}</badge>
                    <i class="fas fa-trash text-danger delete-icon" @click="deleteUserFromGrowthProgram(user)"></i>
                  </div>
                </div>
              </a>
            </div>
            <span v-else>No {{selectedRole}} found</span>
          </div>
        </div>

        <new-growth-program-modal @close="closeNewGrowthProgramModal"
                                  :latestGrowthProgram="latestGrowthProgram"
                                  :show="isNewGrowthProgramModalVisible" />

        <growth-program-users-modal @close="closeGrowthProgramUsersModal"
                                    @get-selected-growth-program-users="getSelectedGrowthProgramUsers"
                                    :isAdminPage="true"
                                    :selectedGrowthProgram="selectedGrowthProgram"
                                    :selectedGrowthProgramUsers="selectedGrowthProgramUsers"
                                    :selectedRole="selectedRole"
                                    :show="isGrowthProgramUsersModalVisible" />
    </div>
</template>

<script>
  import _ from 'lodash';
  import moment from 'moment';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import BackToTopCustom from "@/views/Widgets/BackToTopCustom";
  import dao from '@/dao';
  import NewGrowthProgramModal from '@/views/Modals/GrowthProgram/NewGrowthProgramModal';
  import GrowthProgramUsersModal from '@/views/Modals/GrowthProgram/GrowthProgramUsersModal';
  import GrowthProgramMixin from "@/views/Mixins/GrowthProgramMixin";
  import '@/assets/sass/growthprogram.old.scss';
  import { store } from '@/store';
  const fb = require('@/firebase');

  export default {
    name: 'AdminPage',
    components: {
      BackToTopCustom,
      NewGrowthProgramModal,
      GrowthProgramUsersModal,
    },
    computed: {
      selectedGrowthProgramSubPrograms() {
        return Object.keys(this.selectedGrowthProgram.numberOfTweets);
      },
      displayUsers() {
        if (this.selectedRole === 'customers') {
          return this.selectedGrowthProgramUsers[this.selectedRole]
            .filter(u => u.growthProgramName === this.selectedSubProgram);
        } else {
          return this.selectedGrowthProgramUsers[this.selectedRole];
        }
      }
    },
    data () {
      return {
        growthPrograms: null,
        isNewGrowthProgramModalVisible: false,
        selectedGrowthProgram: null,
        selectedGrowthProgramUsers: null,
        selectedRole: 'customers',
        selectedSubProgram: null,
        latestGrowthProgram: null,
      };
    },
    methods: {
      closeNewGrowthProgramModal() {
        this.isNewGrowthProgramModalVisible = false;
      },
      confirmDelete(action, callback) {
        this.swalModal({
          title: `Delete ${action}`,
          text: `Are you sure you want to delete the ${action}?`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          preConfirm: () => {
            callback();
          },
        });
      },
      deleteGrowthProgram(growthProgram) {
        const deleteGrowthProgram = () => {
          dao.userProfile.getAllActiveUsers().then((res) => {
            res.docs.forEach((user) =>
              dao.growthProgram.deleteGrowthProgramInfo(growthProgram.ref.id, user.id)
              );
            });
          dao.growthProgram.deleteGrowthProgram(growthProgram.ref.id);
        };
        this.confirmDelete('growth program', deleteGrowthProgram);
      },
      deleteUserFromGrowthProgram(user) {
        const deleteCustomer = () => {
          dao.growthProgram.updateGrowthProgramName(user.uid, null);
          dao.growthProgram.deleteUser(this.selectedGrowthProgram.ref.id, user.uid, this.selectedRole);
        };
        const deleteInfluencer = () => {
          deleteGrowthProgramRole(user.uid, 'influencer');
          dao.growthProgram.deleteUser(this.selectedGrowthProgram.ref.id, user.uid, this.selectedRole);
        };
        const deleteWriter = () => deleteGrowthProgramRole(user.uid, 'writer')
          .then(() => this.getSelectedGrowthProgramUsers());
        const deleteUser = () => {
          if (this.selectedRole === 'customers') {
            return deleteCustomer();
          }
          if (this.selectedRole === 'influencers') {
            return deleteInfluencer();
          }
          if (this.selectedRole === 'writers') {
            return deleteWriter();
          }
        };
        const deleteGrowthProgramRole = (userId, role) => {
          const removePermissionIfUserIsntInAnyProgram = () => {
            dao.userProfile.getUser(userId).then(u => {
              const isUserStillInRole = Boolean(_.filter(u.data().growthProgramsRoles, (value) => value.includes(role)).length);
              if (isUserStillInRole) return;
              dao.growthProgram.removeGrowthProgramPermission(userId, role);
            });
          };
          return dao.growthProgram.deleteGrowthProgramRole(this.selectedGrowthProgram.ref.id, user.uid, role)
            .then(() => {
              return removePermissionIfUserIsntInAnyProgram(user.uid, role);
            });
        }
        this.confirmDelete('user', deleteUser);
      },
      getSelectedGrowthProgramUsers() {
        const getUsersData = (role) => {
          const usersData = role.map((user) => user.get().then((doc) => doc.data()));
          return Promise.all(usersData);
        };
        const getWritersData = () => {
          return dao.growthProgram
            .getSelectedGrowthProgramWriters(this.selectedGrowthProgram.ref.id)
            .then(({ docs }) => docs.map((doc) => doc.data()));
        };

        const { customers, influencers } = this.selectedGrowthProgram;
        const customersData = getUsersData(customers);
        const influencersData = getUsersData(influencers);
        const writersData = getWritersData();

        Promise.all([customersData, influencersData, writersData]).then(
          ([customers, influencers, writers]) => {
            this.selectedGrowthProgramUsers = { customers, influencers, writers };
          }
        );
      },
      isSelected(growthProgram) {
        if (!this.selectedGrowthProgram) {
          return false;
        }
        return this.selectedGrowthProgram.ref.id === growthProgram.ref.id;
      },
      switchToGrowthProgram(growthProgram) {
        this.selectedGrowthProgram = growthProgram;
      },
      setSelectedRole(role) {
        this.selectedRole = role;
      },
      showNewGrowthProgramModal() {
        this.isNewGrowthProgramModalVisible = true;
      },
      updateGrowthPrograms() {
        const unsubscribe = fb.growthProgramsCollection
        .onSnapshot(() => {
          this.getGrowthPrograms().then(() => {
            if (this.selectedGrowthProgram) {
              this.selectedGrowthProgram = this.growthPrograms.find(
                (program) => this.selectedGrowthProgram.ref.id === program.ref.id
              );
            }
          });
        });

        store.dispatch('addFirestoreListener', unsubscribe);
      },
    },
    mounted () {
      this.getGrowthPrograms();
      this.updateGrowthPrograms();
    },
    mixins: [GrowthProgramMixin, SwalModalMixin],
    watch: {
      selectedGrowthProgram(val) {
        if (!val) return;
        this.selectedSubProgram = this.selectedGrowthProgramSubPrograms[0];
        this.getSelectedGrowthProgramUsers();
      },
    },
  };
</script>

<style lang="scss">
  .avatar {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
  }
  .delete-icon {
    display: none;
    font-size: 1.2rem;
    &:hover {
      color: #d63031 !important;
    }
  }
  .growth-program-users {
    height: 50vh;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .program-group {
    width: 80%;
    height: 60vh;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .selected {
    color: #2980b9 !important;
  }
  .user {
    &:hover {
      > div {
        .badge {
          display: none;
        }
        .delete-icon {
          display: block;
        }
      }
    }
  }
</style>
