class GrowthProgram {
  constructor(customers, customersTweetsFinished, date, influencers,
              isPublishConfirmed, month, numberOfTweets,
              totalRTsByInfluencers, year) {
    this.customers = customers;
    this.customersTweetsFinished = customersTweetsFinished;
    this.date = date;
    this.influencers = influencers;
    this.isPublishConfirmed = isPublishConfirmed;
    this.month = month;
    this.numberOfTweets = numberOfTweets;
    this.totalRTsByInfluencers = totalRTsByInfluencers;
    this.year = year;
  }

  static newGrowthProgram(date, month, numberOfTweets, year) {
    return new GrowthProgram([], [], date, [], false, month,
      numberOfTweets, null, year);
  }
}

export { GrowthProgram };