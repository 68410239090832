<template>
    <modal :show="show"
           @close="close"
           body-classes="p-0"
           modal-classes="modal-dialog-centered modal-xl">
        <card type="secondary" shadow class="border-0 mb-0">
            <template v-if="users">
              <div v-if="isAdminPage" class="d-flex justify-content-end mb-2">
                <select v-model="subProgram" v-if="selectedRole === 'customers'" class="form-control col-lg-2 col-md-4 col-6">
                  <option v-for="subProgram in selectedGrowthProgramSubPrograms" :key="subProgram" :value="subProgram">
                    {{subProgram}}
                  </option>
                </select>
                <base-button type="primary" :disabled="!isThereCheckedUsers" class="ml-3 text-capitalize" @click="addUsers">Add {{selectedRole}}</base-button>
              </div>
              <div class="list-group users-list">
                <a v-for="user in users" @click.prevent="isAdminPage ? toggleUserCheck(user) : selectUser(user)" :key="user.uid" href="javascript:;" class="list-group-item list-group-item-action">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                      <base-checkbox v-if="isAdminPage" v-model="user.checked"></base-checkbox>
                      <img :src="user.photoURL" class="avatar mr-3" :alt="user.username">
                      <span>{{user.username}}</span>
                    </div>
                    <badge v-if="!isAdminPage" :type="user.areGrowthProgramThreadsFinished ? 'danger' : 'info'">{{displayTweetsStatusForUser(user)}}</badge>
                  </div>
                </a>
              </div>
            </template>
        </card>
    </modal>
</template>

<script>
  import { mapGetters } from 'vuex';
  import dao from '@/dao';
  import ThreadMixin from '@/views/Mixins/ThreadMixin';
  import GrowthProgramMixin from '@/views/Mixins/GrowthProgramMixin';
  const fb = require('@/firebase');

  export default {
    computed: {
      checkedUsers() {
        return this.users.filter(user => user.checked);
      },
      isThereCheckedUsers() {
        if (!this.users) {
          return false;
        } else {
          return Boolean(this.checkedUsers.length);
        }
      },
      selectedGrowthProgramSubPrograms() {
        if (this.selectedGrowthProgram) {
          return Object.keys(this.selectedGrowthProgram.numberOfTweets);
        } else {
          return [];
        }
      },
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
    },
    data() {
      return this.initialState();
    },
    name: 'growth-program-users-modal',
    props: {
      isAdminPage: Boolean,
      selectedGrowthProgram: Object,
      selectedGrowthProgramUsers: Object,
      selectedRole: String,
      selectedUser: Object,
      show: Boolean,
    },
    methods: {
      addUsers() {
        const checkedUsersRefs = this.checkedUsers.map((user) =>
          fb.usersCollection.doc(user.uid)
        );
        const addUsers = () => dao.growthProgram.addUsers(
          this.selectedGrowthProgram.ref.id,
          checkedUsersRefs,
          this.selectedRole,
        );
        const addCustomers = () => {
          if (!this.subProgram) return;
          const updateGrowthProgramName = checkedUsersRefs.forEach((user) =>
            dao.growthProgram.updateGrowthProgramName(user.id, this.subProgram)
          );
          Promise.all([updateGrowthProgramName, addUsers()]).then(() => {
            this.close();
            this.$notify({type: 'success', message: 'Customers were added successfully!'});
          });
        };
        const addInfluencers = () => {
          const addGrowthProgramRole = checkedUsersRefs.forEach((user) =>
            dao.growthProgram.addGrowthProgramRole(
              user.id,
              this.selectedGrowthProgram.ref.id,
              'influencer'
            )
          );
          Promise.all([addGrowthProgramRole, addUsers()]).then(() => {
            this.close();
            this.$notify({type: 'success', message: 'Influencers were added successfully!'});
          });
        };
        const addWriters = () => {
          const addGrowthProgramRole = checkedUsersRefs.map((user) =>
            dao.growthProgram.addGrowthProgramRole(
              user.id,
              this.selectedGrowthProgram.ref.id,
              'writer'
            )
          );
          Promise.all(addGrowthProgramRole).then(() => {
            this.$emit('get-selected-growth-program-users');
            this.close();
            this.$notify({type: 'success', message: 'Writers were added successfully!'});
          });
        };

        if (this.selectedRole === 'customers') {
          return addCustomers();
        }
        if (this.selectedRole === 'influencers') {
          return addInfluencers();
        }
        if (this.selectedRole === 'writers') {
          return addWriters();
        }
      },
      close() {
        if (!this.isAdminPage) {
          if (!this.selectedUser) return;
        }
        this.resetModal();
        this.$emit('close');
      },
      toggleUserCheck(user) {
        user.checked = !user.checked;
      },
      initialState() {
        return {
          users: null,
          subProgram: null,
        };
      },
      async getNonIncludedUsers() {
        const growthProgramUsersUIDs = this.selectedGrowthProgramUsers[this.selectedRole]
          .map(user => user.uid);
        return await dao.userProfile.getAllActiveUsers()
          .then(result => result.docs.map(doc => ({ ...doc.data(), checked: false })))
          .then(users => users.filter(u => growthProgramUsersUIDs.indexOf(u.uid) < 0));
      },
      getSelectedGrowthProgramUsers() {
        const getNumberOfUserTweets = (userDoc) => {
          return dao.growthProgram.getUserGrowthProgramThreads(userDoc, this.selectedGrowthProgram.ref)
            .then(result => result.docs.length)
            .catch(error => {
              console.error(error);
            });
        };

        const selectedGrowthProgramData = this.selectedGrowthProgram;
        const selectedGrowthProgramUsers = selectedGrowthProgramData.customers;
        const usersDocs = selectedGrowthProgramUsers.map((userRef) => userRef.get());

        return Promise.all(usersDocs)
          .then((users) => {
            return users.map((userDoc) =>
              getNumberOfUserTweets(userDoc).then((numberOfTweets) => {
                const numberOfGrowthSubProgramThreads = selectedGrowthProgramData
                  .numberOfTweets[userDoc.data().growthProgramName];
                return {
                  ...userDoc.data(),
                  numberOfTweets,
                  numberOfGrowthSubProgramThreads,
                  areGrowthProgramThreadsFinished: numberOfTweets === numberOfGrowthSubProgramThreads,
                };
              })
            );
          })
          .then((res) => Promise.all(res).then((users) => (this.users = users)))
          .catch((error) => {
            console.error(error);
            alert('An error has occured while fetching users');
          });
      },
      selectUser(user) {
        this.$emit('set-selected-user', user);
        this.close();
      },
    },
    mixins: [ThreadMixin, GrowthProgramMixin],
    watch: {
      selectedGrowthProgram(val) {
        if (!val) return;
        this.getSelectedGrowthProgramUsers();
      },
      selectedUser(val) {
        if (!val) return;
        this.close();
      },
      async show(val) {
        if (!val) return;
        if (this.isAdminPage) {
          this.users = await this.getNonIncludedUsers();
        } else {
          this.getSelectedGrowthProgramUsers();
        }
      },
    },
  };
</script>

<style type="scss">
  .avatar {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
  }
  .users-list {
    height: 20rem;
    overflow: auto;
  }
</style>
